.page {
  position: relative;
  padding-top:125px;
}
.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
  display: none;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
