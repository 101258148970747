.container {
    margin-top: 50px;
    text-align: center;
}

.card {
    background-color: #073271;
    color: white;
    margin-top: 25px;
}

div.modal-header .btn-close{
    padding: 0.5rem 2.5rem;
}


.card h5:hover {
  background: white;
  color: #073271;
  cursor: pointer;
}
