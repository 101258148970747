.adt-fallout-table {
    font-size:13.5px;
    margin:1.5em 0;
}

.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    min-height: 0px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.col {
    height: 30px;
}

#refresh-icon {
    padding-left: 70px;
}

#refresh-icon-btn {
    background-color: #234B91;
    border: #234B91;
}

.accordion-button:not(.collapsed) {
    background-color: #E5E9F1;
    color: #234B91
}

.accordion-button.collapsed {
    background-color: #E5E9F1;
    color: #234B91
}

#num-message-alert {
    text-align: center;
}

.MuiSvgIcon-root {
    color: #8A8B8D;
}

