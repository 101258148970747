body {
  font-family: "Lato", sans-serif;
}

.sidenav {
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -400px;
  opacity:0;
  background-color: #073271;
  overflow-x: hidden;
  transition: all .5s ease-in;
  z-index: 21;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow:hidden;
  box-shadow: 5px 5px 10px grey;
}
.sidenav.enter{
    opacity: 1;
    left: 0;
}

.sidenav .nav-link {
  padding: 8px 16px 8px 16px;
  text-decoration: none;
  font-size: 1em;
  color: white;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid white;
}

.sidenav .nav-link:hover {
  color: #073271;
  background-color: white;
  letter-spacing: 0.1em;
}

.sidenav a:active, .sidear .nav-link {
  color:white;
}

.sidenav .close {
  font-size: 36px;
  background-color: #073271;
  border-color: #073271;
  color: white;
  align-self: flex-end;
  padding:0;
  padding-right:8px;
  cursor: pointer;
}
.sidenav .close:hover {
  color: #FF9900;
}

#menu {
  position: absolute;
  left: 40px;
  top : 15px;
  font-size: 2em;
  font-weight: 800;
  z-index: 21;
  color: #073271;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s;
}

#menu:hover{
    transform: rotate(90deg);
    color: #FF9900;
}
